import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../styles/aggregatedCards.module.scss";
import {
  loadAggregatedDataMultipleApis,
  loadAggregatedDataSingleApi,
} from "../actions/aggregated.actions";
import {
  historicalLatestSingleValueParser,
  parseTotalBandwidthConsumption,
  singleValueParser,
} from "../helpers/parseData";
import SmallCard from "../components/Cards/SmallCard";

const Card = ({ card }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (card.apiNames && card.apiNames.length > 0) {
      dispatch(
        loadAggregatedDataMultipleApis({
          key: card.stateKey,
          apiNames: card.apiNames,
          params: card.params,
          duration: card.duration,
          parserFunction: card.parserFunction,
        })
      );
    }
    if (card.apiName) {
      dispatch(
        loadAggregatedDataSingleApi({
          key: card.stateKey,
          apiName: card.apiName,
          params: card.params,
          duration: card.duration,
          parserFunction: card.parserFunction,
        })
      );
    }
  }, [
    card.apiName,
    card.apiNames,
    card.duration,
    card.params,
    card.parserFunction,
    card.stateKey,
    dispatch,
  ]);

  if (typeof card.value === "object") {
    return (
      <SmallCard title={card.title}>
        {card.loading ? (
          <span className={styles.aggregatedValue}>-</span>
        ) : (
          <table>
            <tbody>
              {Object.entries(card.value) &&
                Object.entries(card.value).length > 0 &&
                Object.entries(card.value).map(([key, value]) => {
                  return (
                    <tr key={key} className={styles.aggregatedValue}>
                      <td>{key}</td>
                      <td> : </td>
                      <td>{value.toLocaleString()}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}
      </SmallCard>
    );
  }
  return (
    <SmallCard title={card.title}>
      {card.loading ? (
          <span className={styles.aggregatedValue}>-</span>
          ) : (
        <span className={styles.aggregatedValue}>
          {card.value.toLocaleString()}
        </span>
      )}
    </SmallCard>
  );
};
const AggregatedCards = () => {
  const state = useSelector((state) => state.aggregated);
  const {
    currentActiveSessionCount,
    currentActiveNodeCount,

    todaySessionAddressCount,
    todaySessionBytes,

    totalSessionAddressCount,
    totalSessionBytes,
  } = state;

  const cards = [
    {
      ...currentActiveSessionCount,
      parserFunction: singleValueParser,
    },
    {
      ...currentActiveNodeCount,
      parserFunction: singleValueParser,
    },
    {
      ...todaySessionAddressCount,
      parserFunction: historicalLatestSingleValueParser,
    },
    {
      ...todaySessionBytes,
      parserFunction: parseTotalBandwidthConsumption,
    },
    {
      ...totalSessionAddressCount,
      parserFunction: singleValueParser,
    },
    {
      ...totalSessionBytes,
      parserFunction: parseTotalBandwidthConsumption,
    },
  ];

  return (
    <div className={styles.aggregatedCards}>
      {cards.map((card) => {
        return <Card key={card.stateKey} card={card} />;
      })}
    </div>
  );
};

export default AggregatedCards;
