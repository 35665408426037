import React from "react";

const Medium = () => {
  return (
    <svg
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2,
      }}
      viewBox="0 0 512 512"
      width="21"
      height="21"
    >
      <path
        fill="#EFF4FF"
        d="M256,0c141.29,0 256,114.71 256,256c0,141.29 -114.71,256 -256,256c-141.29,0 -256,-114.71 -256,-256c0,-141.29 114.71,-256 256,-256Zm149.439,390.919l0,-5.04l-23.389,-22.963c-2.065,-1.573 -3.089,-4.161 -2.661,-6.721l0,-168.719c-0.428,-2.561 0.596,-5.148 2.661,-6.722l23.95,-22.962l0,-5.041l-82.912,0l-59.105,147.438l-67.227,-147.438l-86.975,0l0,5.041l28.012,33.744c2.751,2.478 4.154,6.119 3.781,9.802l0,132.595c0.815,4.782 -0.692,9.669 -4.06,13.161l-31.514,38.224l0,5.04l89.356,0l0,-5.04l-31.513,-38.224c-3.394,-3.485 -4.995,-8.34 -4.342,-13.161l0,-114.673l78.433,171.098l9.103,0l67.367,-171.098l0,136.376c0,3.639 0,4.34 -2.382,6.719l-24.229,23.524l0,5.04l117.646,0Z"
      />
    </svg>
  );
};

export default Medium;
