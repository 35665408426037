import React from "react";
import PropTypes from "prop-types";
import "./cards.scss";

const Card = ({ children }) => {
  return (
    <div className="card">
      <div className="card-container">{children}</div>
    </div>
  );
};

Card.defaultProps = {
  children: <></>,
};

Card.propTypes = {
  children: PropTypes.any.isRequired,
};

export default Card;
