import React from "react";
import PropTypes from "prop-types";
import Card from "./Card";

const SmallCard = ({ icon: Icon, title, children }) => {
  return (
    <Card>
      <section className="card-small">
        <section className="card-small-top">
          {Icon && <Icon />}
          <p>{title}</p>
        </section>
        <section className="card-small-bottom">{children}</section>
      </section>
    </Card>
  );
};

SmallCard.defaultProps = {
  title: "",
  children: <></>,
  icon: null,
};

SmallCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  icon: PropTypes.any,
};

export default SmallCard;
