import { createAsyncThunk } from "@reduxjs/toolkit";
import { durations } from "../constants/graph.constants.js";
import {
  loadHistoricalBandwidthConsumed,
  loadHistoricalSessionAddressCount,
  loadHistoricalActiveNodeCount,
  loadHistoricalActiveSessionCount,
  loadHistoricalRevenuePaid,
} from "./historicals.actions";

export const loadInitialGraphData = createAsyncThunk(
  "historical/loadInitialGraphData",
  async (_, { rejectWithValue, dispatch }) => {
    const duration = durations.DAY;
    try {
      dispatch(loadHistoricalActiveSessionCount(duration));
      dispatch(loadHistoricalActiveNodeCount(duration));
      dispatch(loadHistoricalSessionAddressCount(duration));
      dispatch(loadHistoricalRevenuePaid(duration));
      dispatch(loadHistoricalBandwidthConsumed(duration));
    } catch (error) {
      return rejectWithValue({ duration });
    }
  }
);
