import React from "react";
import { SentinelLogo } from "../../assets/logos";
import SocialLinks from "../SocialLinks/SocialLinks";
import { groups } from "../../constants/links.constants";
import styles from "./footer.module.scss";

const Footer = () => {
  const logoWidth =
    window.innerWidth > 1000
      ? "12em"
      : window.innerWidth > 400
      ? "8em"
      : "6em";

  return (
    <div className={styles.footer}>
      <div className={styles.footerContainer}>
        <section className={styles.footerContainerLeft}>
          <section className={styles.footerContainerLeftLogo}>
            <SentinelLogo width={logoWidth} />
          </section>
          <section className={styles.footerContainerLeftSocialLinks}>
            <SocialLinks />
          </section>
        </section>
        <section className={styles.footerContainerRight}>
          {groups.map(({ title, links }) => {
            return (
              <section
                className={styles.footerContainerRightCommunity}
                key={title}
              >
                <p className={styles.footerContainerRightCommunityTitle}>
                  {title}
                </p>
                <section className={styles.footerContainerRightCommunityLinks}>
                  {links.map((link) => {
                    return (
                      <a
                        key={link.title}
                        className={styles.footerContainerRightCommunityLink}
                        href={link.href}
                        target={"_blank"}
                        rel={"noreferrer"}
                      >
                        {link.title}
                      </a>
                    );
                  })}
                </section>
              </section>
            );
          })}
        </section>
      </div>
    </div>
  );
};

export default Footer;
