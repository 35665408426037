import React from "react";
import PropTypes from "prop-types";
import "./duration-buttons.scss";
import { durations } from "../../constants/graph.constants";

const DurationButtons = ({
  isLoading = false,
  duration,
  onDurationChange = () => ({}),
}) => {
  const [value, setValue] = React.useState(duration);
  const onValueChange = (event) => {
    const duration = event.target.getAttribute("duration");
    setValue(duration);
    onDurationChange(duration);
  };
  const buttons = [
    {
      title: durations.DAY,
      value: durations.DAY,
    },
    {
      title: durations.WEEK,
      value: durations.WEEK,
    },
    {
      title: durations.MONTH,
      value: durations.MONTH,
    },
    {
      title: durations.YEAR,
      value: durations.YEAR,
    },
  ];
  return (
    <section className={`duration-buttons`}>
      {buttons.map((button) => {
        const disabled = button.value === value;
        return (
          <button
            key={button.title}
            className={`graph-button ${disabled && "graph-button-active"}`}
            onClick={onValueChange}
            disabled={disabled || isLoading}
            duration={button.value}
          >
            {button.title}
          </button>
        );
      })}
    </section>
  );
};

DurationButtons.defaultProps = {
  handleOnChange: () => ({}),
  duration: durations.DAY,
};

DurationButtons.propTypes = {
  duration: PropTypes.oneOf([...Object.values(durations), "", null, undefined])
    .isRequired,
  handleOnChange: PropTypes.func.isRequired,
};

export default DurationButtons;
