import React from "react";
import { SentinelLogo } from "../../assets/logos";
import styles from "./header.module.scss";
import SocialLinks from "../SocialLinks/SocialLinks";
import PropTypes from "prop-types";

const Header = ({ links }) => {
  return (
    <section className={styles.header}>
      <SentinelLogo width={"8em"} />
      <div className={styles.hideMobile}>
        <SocialLinks />
      </div>
    </section>
  );
};

Header.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      to: PropTypes.object.isRequired,
    }).isRequired
  ),
};

Header.defaultProps = {
  links: [],
};

export default Header;
