import React from "react";

const Twitter = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2738 0.842285H18.2477L11.7507 8.88675L19.3939 19.8334H13.4084L8.7211 13.1943L3.35773 19.8334H0.382071L7.33126 11.229L0 0.842285H6.13561L10.3725 6.91069L15.272 0.842285H15.2738ZM14.2301 17.9051H15.878L5.24111 2.66933H3.4728L14.2301 17.9051Z"
        fill="#EFF4FF"
      />
    </svg>
  );
};

export default Twitter;
