import { createSlice } from "@reduxjs/toolkit";
import { durations } from "../constants/graph.constants";
import { loadHistoricalActiveNodeCount, loadHistoricalActiveSessionCount, loadHistoricalBandwidthConsumed, loadHistoricalRevenuePaid, loadHistoricalSessionAddressCount } from "../actions/historicals.actions";

const graphProps = {
  loading: true,
  duration: durations.DAY,
  [durations.DAY]: {
    labels: [],
    values: {},
  },
  [durations.WEEK]: {
    labels: [],
    values: {},
  },
  [durations.MONTH]: {
    labels: [],
    values: {},
  },
  [durations.YEAR]: {
    labels: [],
    values: {},
  },
};

const historicalSlice = createSlice({
  name: "historical",
  initialState: {
    activeNodes: {
      ...graphProps,
      title: "Active Nodes",
      onDurationChange: "loadHistoricalActiveNodeCount",
    },
    sessionAddresses: {
      ...graphProps,
      title: "Unique Users",
      onDurationChange: "loadHistoricalSessionAddressCount",
    },
    activeSessions: {
      ...graphProps,
      title: "dVPN Sessions",
      onDurationChange: "loadHistoricalActiveSessionCount",
    },
    bandwidthConsumed: {
      ...graphProps,
      title: "Data Consumed",
      onDurationChange: "loadHistoricalBandwidthConsumed",
      isBandwidth: true,
    },
    revenuePaid: {
      ...graphProps,
      title: "$DVPN earned by Node Hosts",
      onDurationChange: "loadHistoricalRevenuePaid",
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadHistoricalActiveSessionCount.fulfilled, (state, { payload }) => {
        return {
          ...state,
          activeSessions: {
            ...state.activeSessions,
            duration: payload?.duration,
            loading: false,
            ...(payload?.data
              ? {
                  [payload.duration]: {
                    ...state.activeSessions[payload.duration],
                    labels: payload.data.labels,
                    values: {
                      [state.activeSessions.title]: payload.data.values,
                    },
                  },
                }
              : {}),
          },
        };
      })
      .addCase(loadHistoricalActiveSessionCount.pending, (state) => {
        return {
          ...state,
          activeSessions: {
            ...state.activeSessions,
            loading: true,
          },
        };
      })
      .addCase(loadHistoricalActiveSessionCount.rejected, (state, { payload }) => {
        return {
          ...state,
          activeSessions: {
            ...state.activeSessions,
            loading: false,
            duration: payload?.duration,
          },
        };
      })
      .addCase(loadHistoricalActiveNodeCount.pending, (state) => {
        return {
          ...state,
          activeNodes: {
            ...state.activeNodes,
            loading: true,
          },
        };
      })
      .addCase(loadHistoricalActiveNodeCount.rejected, (state, { payload }) => {
        return {
          ...state,
          activeNodes: {
            ...state.activeNodes,
            loading: false,
            duration: payload?.duration,
          },
        };
      })
      .addCase(loadHistoricalActiveNodeCount.fulfilled, (state, { payload }) => {
        return {
          ...state,
          activeNodes: {
            ...state.activeNodes,
            duration: payload?.duration,
            loading: false,
            ...(payload?.data
              ? {
                  [payload.duration]: {
                    ...state.activeNodes[payload.duration],
                    labels: payload.data.labels,
                    values: {
                      [state.activeNodes.title]: payload.data.values,
                    },
                  },
                }
              : {}),
          },
        };
      })
      .addCase(loadHistoricalSessionAddressCount.pending, (state) => {
        return {
          ...state,
          sessionAddresses: {
            ...state.sessionAddresses,
            loading: true,
          },
        };
      })
      .addCase(loadHistoricalSessionAddressCount.rejected, (state, { payload }) => {
        return {
          ...state,
          sessionAddresses: {
            ...state.sessionAddresses,
            loading: false,
            duration: payload?.duration,
          },
        };
      })
      .addCase(loadHistoricalSessionAddressCount.fulfilled, (state, { payload }) => {
        return {
          ...state,
          sessionAddresses: {
            ...state.sessionAddresses,
            loading: false,
            duration: payload.duration,
            ...(payload?.data
              ? {
                  [payload.duration]: {
                    ...state.sessionAddresses[payload.duration],
                    labels: payload.data.labels,
                    values: {
                      [state.sessionAddresses.title]: payload.data.values,
                    },
                  },
                }
              : {}),
          },
        };
      })
      .addCase(loadHistoricalBandwidthConsumed.pending, (state) => {
        return {
          ...state,
          bandwidthConsumed: {
            ...state.bandwidthConsumed,
            loading: true,
          },
        };
      })
      .addCase(loadHistoricalBandwidthConsumed.rejected, (state, { payload }) => {
        return {
          ...state,
          bandwidthConsumed: {
            ...state.bandwidthConsumed,
            loading: false,
            duration: payload?.duration,
          },
        };
      })
      .addCase(loadHistoricalBandwidthConsumed.fulfilled, (state, { payload }) => {
        return {
          ...state,
          bandwidthConsumed: {
            ...state.bandwidthConsumed,
            loading: false,
            duration: payload.duration,
            ...(payload?.values ? { [payload.duration]: { ...payload.values } } : {}),
          },
        };
      })
      .addCase(loadHistoricalRevenuePaid.pending, (state) => {
        return {
          ...state,
          revenuePaid: {
            ...state.revenuePaid,
            loading: true,
          },
        };
      })
      .addCase(loadHistoricalRevenuePaid.rejected, (state, { payload }) => {
        return {
          ...state,
          revenuePaid: {
            ...state.revenuePaid,
            loading: false,
            duration: payload?.duration,
          },
        };
      })
      .addCase(loadHistoricalRevenuePaid.fulfilled, (state, { payload }) => {
        return {
          ...state,
          revenuePaid: {
            ...state.revenuePaid,
            loading: false,
            duration: payload.duration,
            ...(payload?.values ? { [payload.duration]: { ...payload.values } } : {}),
          },
        };
      });
  },
});

export default historicalSlice.reducer;
