import React, { useEffect } from "react";
import styles from "./styles/app.module.scss";
import { useDispatch } from "react-redux";
import { loadInitialGraphData } from "./actions/initiate.actions";
import GraphCards from "./containers/GraphCards";
import AggregatedCards from "./containers/AggregatedCards";
import Banner from "./components/Banner";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadInitialGraphData());
  }, [dispatch]);
  return (
    <div className={styles.app}>
      <Banner />
      <div className={styles.navBar}>
        <Header />
      </div>
      <div className={styles.pageHeading}>
        <section className={styles.bannerTop}>
          <p className={styles.pageTitle}>dVPN Network Statistics</p>
          <p className={styles.description}>
            Explore the growth and activity of the Sentinel dVPN network through
            insightful charts and data
          </p>
        </section>
        <p className={styles.pageSubTitle}>Overview</p>
      </div>
      <AggregatedCards />
      <GraphCards />
      <div className={styles.footer}>
        <Footer />
      </div>
    </div>
  );
};

export default App;
