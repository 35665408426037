import React from "react";
import PropTypes from "prop-types";
import Card from "./Card";
import Graph from "../Graph/Graph";
import { durations } from "../../constants/graph.constants";
import DurationButtons from "../DurationButtons/DurationButtons";
import Loader from "../Loader/Loader";

const GraphCard = ({
  duration,
  dataPoints,
  labels,
  onDurationChange,
  title,
  showLegend,
  colors,
  isLoading,
  isFullWidth,
  isBandwidth,
}) => {
  return (
    <Card>
      <div className={`card-graph`}>
        <section className="card-graph-top">
          <p className="card-graph-title">{title}</p>
          <DurationButtons
            duration={duration}
            onDurationChange={onDurationChange}
            isLoading={isLoading}
          />
        </section>
        <section
          className={`card-graph-bottom ${
            isFullWidth ? "card-graph-bottom-full" : ""
          }`}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <Graph
              isBandwidth={isBandwidth}
              isFullWidth={isFullWidth}
              showLegend={showLegend}
              colors={colors}
              labels={labels}
              data={dataPoints}
            />
          )}
        </section>
      </div>
    </Card>
  );
};

GraphCard.defaultProps = {
  duration: "",
  dataPoints: {},
  labels: [],
  onDurationChange: () => ({}),
  title: "",
  showLegend: true,
  colors: [],
  isLoading: false,
  isFullWidth: false,
  isBandwidth: false,
};

GraphCard.propTypes = {
  duration: PropTypes.oneOf([...Object.values(durations), "", null, undefined])
    .isRequired,
  dataPoints: PropTypes.object.isRequired,
  labels: PropTypes.array.isRequired,
  onDurationChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  showLegend: PropTypes.bool,
  colors: PropTypes.array,
  isLoading: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isBandwidth: PropTypes.bool,
};

export default GraphCard;
