import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchData } from "../api/fetchData";
import { setAggregatedValue } from "../reducers/aggregated.reducer";

export const loadAggregatedDataSingleApi = createAsyncThunk(
  "aggregated/loadAggregatedDataSingleApi",
  async (
    {
      key = "",
      apiName = "",
      params = {},
      duration = null,
      parserFunction = () => ({}),
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await fetchData(apiName, duration, params);
      const value = parserFunction(response);
      return dispatch(setAggregatedValue({ key, value }));
    } catch (error) {
      return rejectWithValue();
    }
  }
);

export const loadAggregatedDataMultipleApis = createAsyncThunk(
  "aggregated/loadAggregatedDataMultipleApis",
  async (
    {
      key = "",
      apiNames = [],
      params = {},
      duration = null,
      parserFunction = () => ({}),
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const apis = apiNames.reduce((prevApi, currApi) => {
        return [...prevApi, fetchData(currApi, duration, params)];
      }, []);
      const response = await Promise.all(apis);
      const value = parserFunction(response);
      return dispatch(setAggregatedValue({ key, value }));
    } catch (error) {
      return rejectWithValue();
    }
  }
);
