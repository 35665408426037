import { createSlice } from "@reduxjs/toolkit";
import { durations } from "../constants/graph.constants";

const aggregatedSlice = createSlice({
  name: "aggregated",
  initialState: {
    currentActiveSessionCount: {
      value: 0,
      loading: true,
      title: "Users Online",
      stateKey: "currentActiveSessionCount",
      apiName: "CurrentSessionCount",
      params: {
        status: "active",
      },
    },
    currentActiveNodeCount: {
      value: 0,
      loading: true,
      title: "dVPN Nodes Online",
      stateKey: "currentActiveNodeCount",
      apiName: "CurrentNodeCount",
      params: {
        status: "active",
      },
    },
    todaySessionAddressCount: {
      value: 0,
      loading: true,
      title: "Today's Unique Users",
      stateKey: "todaySessionAddressCount",
      apiName: "HistoricalSessionAddressCount",
      duration: durations.DAY,
      params: {
        sort: "-timestamp",
        limit: 1,
      },
    },
    todaySessionBytes: {
      value: 0,
      loading: true,
      title: "Today's Data Consumed",
      duration: durations.DAY,
      params: {
        sort: "-timestamp",
        limit: 1,
      },
      stateKey: "todaySessionBytes",
      apiName: "HistoricalSessionBytes",
    },
    totalSessionAddressCount: {
      value: 0,
      loading: true,
      title: "Total Unique Users",
      stateKey: "totalSessionAddressCount",
      apiName: "CurrentSessionAddressCount",
    },
    totalSessionBytes: {
      value: 0,
      loading: true,
      title: "Total Data Consumed",
      stateKey: "totalSessionBytes",
      apiName: "TotalSessionBytes",
      duration: durations.YEAR,
    },
  },
  reducers: {
    setAggregatedValue: (state, { payload }) => {
      const stateKey = payload.key;
      return {
        ...state,
        [stateKey]: {
          ...state[stateKey],
          value: payload.value,
          loading: false,
        },
      };
    },
  },
});

export const { setAggregatedValue } = aggregatedSlice.actions;
export default aggregatedSlice.reducer;
