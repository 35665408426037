import React from "react";
import { social } from "../../constants/links.constants";
import styles from "./social-links.module.scss";

const SocialLinks = () => {
  return (
    <section className={styles.social}>
      {social.map(({ title, logo: Logo, href }) => (
        <a
          key={title}
          target={"_blank"}
          href={href}
          title={title}
          rel={"noreferrer"}
        >
          <Logo />
        </a>
      ))}
    </section>
  );
};

export default SocialLinks;
