import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchData } from "../api/fetchData";
import {
  parseHistoricalCoins,
  parseHistoricalValues,
  processBandwidthConsumedData,
} from "../helpers/parseData";

export const loadHistoricalActiveSessionCount = createAsyncThunk(
  "historical/loadHistoricalActiveSessionCount",
  async (duration, { getState, rejectWithValue }) => {
    try {
      const active = getState().historical.activeSessions;
      const { labels } = active[duration];
      if (labels && labels.length > 0) {
        return { duration };
      }
      const response = await fetchData(
        "HistoricalActiveSessionCount",
        duration
      );
      const activeSessionCount = parseHistoricalValues(response, duration);
      return { duration, data: activeSessionCount };
    } catch (error) {
      return rejectWithValue({ duration });
    }
  }
);

export const loadHistoricalActiveNodeCount = createAsyncThunk(
  "historical/loadHistoricalActiveNodeCount",
  async (duration, { getState, rejectWithValue }) => {
    try {
      const active = getState().historical.activeNodes;
      const { labels } = active[duration];
      if (labels && labels.length > 0) {
        return { duration };
      }
      const response = await fetchData("HistoricalActiveNodeCount", duration);
      const historicalActiveNodeCount = parseHistoricalValues(
        response,
        duration
      );
      return { duration, data: historicalActiveNodeCount };
    } catch (error) {
      return rejectWithValue({ duration });
    }
  }
);

export const loadHistoricalSessionAddressCount = createAsyncThunk(
  "historical/loadHistoricalSessionAddressCount",
  async (duration, { getState, rejectWithValue }) => {
    try {
      const active = getState().historical.sessionAddresses;
      const { labels } = active[duration];
      if (labels && labels.length > 0) {
        return { duration };
      }
      const response = await fetchData(
        "HistoricalSessionAddressCount",
        duration
      );
      const historicalSessionAddressCount = parseHistoricalValues(
        response,
        duration
      );
      return { duration, data: historicalSessionAddressCount };
    } catch (error) {
      return rejectWithValue({ duration });
    }
  }
);

export const loadHistoricalBandwidthConsumed = createAsyncThunk(
  "historical/loadBandwidthConsumed",
  async (duration, { rejectWithValue, getState }) => {
    try {
      const active = getState().historical.bandwidthConsumed;
      const { labels } = active[duration];
      if (labels && labels.length > 0) {
        return { duration };
      }
      const response = await fetchData("HistoricalSessionBytes", duration);
      const bandwidthConsumption = processBandwidthConsumedData(
        response,
        duration
      );
      return { duration, values: bandwidthConsumption };
    } catch (_) {
      return rejectWithValue({ duration });
    }
  }
);

export const loadHistoricalRevenuePaid = createAsyncThunk(
  "historical/loadRevenuePaidToStakers",
  async (duration, { getState, rejectWithValue }) => {
    try {
      const active = getState().historical.revenuePaid;
      const { labels } = active[duration];
      if (labels && labels.length > 0) {
        return { duration };
      }

      const result = await Promise.all([
        fetchData("HistoricalBytesPayment", duration),
        fetchData("HistoricalHoursPayment", duration),
        fetchData("HistoricalPlanPayment", duration),
        fetchData("HistoricalBytesStakingReward", duration),
        fetchData("HistoricalPlanStakingReward", duration),
        fetchData("HistoricalHoursStakingReward", duration),
      ]);

      const revenuePaid = parseHistoricalCoins(result.flat(1), duration);
      return { duration, values: revenuePaid };
    } catch (error) {
      return rejectWithValue({ duration });
    }
  }
);
