import React from "react";

const Telegram = () => {
  return (
    <svg
      width="21"
      height="19"
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8047 0.742978C18.8047 0.742978 20.6663 -0.0460849 20.5106 1.87006C20.4593 2.65912 19.994 5.4211 19.6318 8.40808L18.3907 17.257C18.3907 17.257 18.2872 18.5534 17.3562 18.7789C16.4257 19.0039 15.0294 17.9899 14.7706 17.7643C14.5636 17.5951 10.8923 15.0586 9.59946 13.819C9.23721 13.4805 8.82321 12.8044 9.65121 12.0154L15.0807 6.37891C15.7012 5.70287 16.3217 4.12475 13.7361 6.04089L6.4959 11.3951C6.4959 11.3951 5.66838 11.9591 4.11732 11.4518L0.755485 10.3242C0.755485 10.3242 -0.485557 9.47891 1.63476 8.6336C6.8064 5.98464 13.1673 3.27944 18.8038 0.742978H18.8047Z"
        fill="#EFF4FF"
      />
    </svg>
  );
};

export default Telegram;
