import { configureStore } from "@reduxjs/toolkit";
import historicalReducer from "../reducers/historical.reducer";
import aggregatedReducer from "../reducers/aggregated.reducer";

export const store = configureStore({
  reducer: {
    historical: historicalReducer,
    aggregated: aggregatedReducer,
  },
});
