import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../styles/graphs.module.scss";
import {
  loadHistoricalActiveNodeCount,
  loadHistoricalActiveSessionCount,
  loadHistoricalBandwidthConsumed,
  loadHistoricalRevenuePaid,
  loadHistoricalSessionAddressCount,
} from "../actions/historicals.actions";
import { sortByKeys } from "../helpers/parseData";
import GraphCard from "../components/Cards/GraphCard";

const GraphCards = () => {
  const state = useSelector((state) => state.historical);
  const noOfGraphs = Object.values(state).length;
  const dispatch = useDispatch();

  const dispatchers = {
    loadHistoricalActiveNodeCount: loadHistoricalActiveNodeCount,
    loadHistoricalSessionAddressCount: loadHistoricalSessionAddressCount,
    loadHistoricalActiveSessionCount: loadHistoricalActiveSessionCount,
    loadHistoricalBandwidthConsumed: loadHistoricalBandwidthConsumed,
    loadHistoricalRevenuePaid: loadHistoricalRevenuePaid,
  };
  const handleDurationChange = (duration, dispatcher) => {
    dispatch(dispatcher(duration));
  };
  return (
    <div className={styles.graphCards}>
      <div className={styles.graphs}>
        {Object.values(state).map((graph, index) => {
          const noOfKeys =
            Object.keys(graph[graph.duration]?.values)?.length ?? 0;
          const ordered = sortByKeys({ ...graph[graph.duration]?.values });
          const colors =
            noOfKeys > 2
              ? [
                  "#0080FE",
                  "#264286",
                  "#FFFFFF",
                  "#1F51FF",
                  "#0096FF",
                  "#013DB3",
                ]
              : ["#264286", "#FFFFFF", "#1F51FF"];
          const fullWidth = noOfGraphs % 2 === 1 && noOfGraphs - 1 === index;
          return (
            <div
              key={graph.title}
              className={` ${fullWidth ? `${styles.fullWidthGraph}` : ""}`}
            >
              <GraphCard
                duration={graph.duration}
                labels={graph[graph.duration].labels}
                dataPoints={{ ...ordered }}
                showLegend={noOfKeys > 1}
                title={graph.title}
                colors={colors}
                isBandwidth={graph.isBandwidth}
                onDurationChange={(duration) =>
                  handleDurationChange(
                    duration,
                    dispatchers[graph.onDurationChange]
                  )
                }
                isLoading={graph.loading}
                isFullWidth={fullWidth}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GraphCards;
