import React from "react";

const Discord = () => {
  return (
    <svg
      width="25"
      height="19"
      viewBox="0 0 25 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7115 1.70782C19.1819 1.00594 17.5415 0.488822 15.8264 0.192649C15.7952 0.186933 15.764 0.201217 15.7479 0.229787C15.5369 0.605002 15.3033 1.0945 15.1396 1.47924C13.2949 1.20308 11.4597 1.20308 9.65285 1.47924C9.48918 1.08595 9.24702 0.605002 9.03511 0.229787C9.01902 0.202171 8.98781 0.187886 8.95658 0.192649C7.24244 0.487876 5.60209 1.00499 4.07147 1.70782C4.05822 1.71353 4.04686 1.72306 4.03932 1.73544C0.927923 6.3838 0.075581 10.9179 0.493711 15.3958C0.495603 15.4177 0.507901 15.4387 0.524929 15.452C2.57774 16.9595 4.56624 17.8747 6.51781 18.4814C6.54904 18.4909 6.58214 18.4795 6.60201 18.4537C7.06366 17.8233 7.47517 17.1586 7.82801 16.4595C7.84883 16.4186 7.82895 16.37 7.7864 16.3538C7.13366 16.1062 6.51213 15.8043 5.91426 15.4615C5.86697 15.4339 5.86318 15.3663 5.90669 15.3339C6.0325 15.2396 6.15835 15.1415 6.27849 15.0425C6.30022 15.0244 6.33051 15.0206 6.35606 15.032C10.2838 16.8253 14.5361 16.8253 18.4175 15.032C18.443 15.0196 18.4733 15.0234 18.496 15.0415C18.6162 15.1406 18.742 15.2396 18.8688 15.3339C18.9123 15.3663 18.9094 15.4339 18.8621 15.4615C18.2643 15.811 17.6427 16.1062 16.989 16.3529C16.9465 16.3691 16.9276 16.4186 16.9484 16.4595C17.3088 17.1576 17.7203 17.8223 18.1734 18.4528C18.1924 18.4795 18.2264 18.4909 18.2576 18.4814C20.2187 17.8747 22.2071 16.9595 24.26 15.452C24.2779 15.4387 24.2893 15.4186 24.2912 15.3967C24.7916 10.2198 23.453 5.72288 20.7427 1.73638C20.7361 1.72306 20.7248 1.71353 20.7115 1.70782ZM8.41455 12.6692C7.23203 12.6692 6.25766 11.5836 6.25766 10.2503C6.25766 8.91701 7.21313 7.83137 8.41455 7.83137C9.6254 7.83137 10.5903 8.92655 10.5714 10.2503C10.5714 11.5836 9.61594 12.6692 8.41455 12.6692ZM16.3893 12.6692C15.2068 12.6692 14.2324 11.5836 14.2324 10.2503C14.2324 8.91701 15.1879 7.83137 16.3893 7.83137C17.6002 7.83137 18.5651 8.92655 18.5462 10.2503C18.5462 11.5836 17.6002 12.6692 16.3893 12.6692Z"
        fill="#EFF4FF"
      />
    </svg>
  );
};

export default Discord;
