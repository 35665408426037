import { durations } from "../constants/graph.constants";

const byte = 1;
const kiloByte = 1000 * byte;
const megaByte = 1000 * kiloByte;
const gigaByte = 1000 * megaByte;
const teraByte = 1000 * gigaByte;
const petaByte = 1000 * teraByte;
const zetaByte = 1000 * petaByte;

const matchStakers = (value) => {
  const stakers = {
    "ibc/B1C0DDB14F25279A2026BC8794E12B259F8BDA546A3C5132CCAEE4431CE36783": "DEC",
    "ibc/31FEE1A2A9F9C01113F90BD0BBCCE8FD6BBB8585FAF109A2101827DD1D5B95B8": "SCRT",
    udvpn: "DVPN",
    "ibc/ED07A3391A112B175915CD8FAF43A2DA8E4790EDE12566649D0C2F97716B8518": "OSMO",
  };
  return stakers[value] ?? value;
};

export const parseBytes = (bytes) => {
  switch (true) {
    case bytes >= zetaByte:
      return `${(bytes / zetaByte).toFixed(2)} ZB`;
    case bytes >= petaByte:
      return `${(bytes / petaByte).toFixed(2)} PB`;
    case bytes >= teraByte:
      return `${Number((bytes / teraByte).toFixed(2)).toLocaleString()} TB`;
    case bytes >= gigaByte:
      return `${Number((bytes / gigaByte).toFixed(2)).toLocaleString()} GB`;
    case bytes >= megaByte:
      return `${Number((bytes / megaByte).toFixed(2)).toLocaleString()} MB`;
    case bytes >= kiloByte:
      return `${Number((bytes / kiloByte).toFixed(2)).toLocaleString()} KB`;
    default:
      return `${Number(bytes).toLocaleString()} B`;
  }
};

const parseDatesForDuration = (timestamp, duration) => {
  const dt = new Date(timestamp);
  switch (duration) {
    case durations.MONTH:
      return `${dt.getMonth() + 1}/${dt.getFullYear()}`;
    case durations.YEAR:
      return `${dt.getFullYear()}`;
    case durations.WEEK:
    case durations.DAY:
    default:
      return `${dt.getDate()}/${dt.getMonth() + 1}/${dt.getFullYear()}`;
  }
};

export const parseHistoricalCoins = (res, duration) => {
  let sDenoms = new Set();
  res.forEach((a) => a?.value.forEach((b) => sDenoms.add(b?.denom)));
  var oDenoms = {};
  sDenoms.forEach((denom) => (oDenoms[denom] = 0));

  let oData = {};
  res.forEach((a) => {
    if (a) {
      if (oData.hasOwnProperty(a?.timestamp) === false) {
        oData[a?.timestamp] = {
          timestamp: a?.timestamp,
          value: { ...oDenoms },
        };
      }

      a?.value.forEach((b) => {
        oData[a?.timestamp].value[b.denom] += Number.parseInt(b.amount);
      });
    }
  });

  let values = {};
  sDenoms.forEach((denom) => (values[matchStakers(denom)] = []));
  Object.keys(oData).forEach((t) => {
    sDenoms.forEach((denom) => {
      values[matchStakers(denom)].push(oData[t].value[denom] / 1e6);
    });
  });
  const labels = Object.values(oData).reduce((prev, curr) => {
    const date = parseDatesForDuration(curr.timestamp, duration);
    return [...prev, date];
  }, []);
  return { labels, values };
};

export const parseHistoricalValues = (data, duration) => {
  const values = data.reduce((prev, curr) => {
    return [...prev, curr.value];
  }, []);
  const labels = data.reduce((prev, curr) => {
    const date = parseDatesForDuration(curr.timestamp, duration);
    return [...prev, date];
  }, []);
  return { labels, values };
};

const parseBandwidthValue = (data, key) => {
  let result = data.reduce(function (prev, curr) {
    const bytes = Number.parseInt(curr.value[key]);
    return [...prev, bytes];
  }, []);
  return result;
};

export const processBandwidthConsumedData = (data, duration) => {
  const labels = data.reduce((prev, curr) => {
    const date = parseDatesForDuration(curr.timestamp, duration);
    return [...prev, date];
  }, []);
  const Uploads = parseBandwidthValue(data, "upload");
  const Downloads = parseBandwidthValue(data, "download");
  return {
    labels,
    values: { Downloads, Uploads },
  };
};

export const parseTotalBandwidthConsumption = (result) => {
  const totalBytes = result.reduce((prev, curr) => {
    return prev + Number.parseInt(curr.value.download) + Number.parseInt(curr.value.upload);
  }, 0);

  return parseBytes(totalBytes);
};

export const singleValueParser = (response) => {
  return Number.parseInt(response[0].value);
};

export const historicalLatestSingleValueParser = (response) => {
  return [...response][0].value ?? 0;
};

export const sortByKeys = (obj) => {
  const ordered = Object.keys(obj)
    .sort()
    .reduce((prev, curr) => {
      return { ...prev, [curr]: obj[curr] };
    }, {});

  return ordered;
};
