import React from "react";

const Github = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.41876 0.34358C6.16973 0.608572 6.88413 0.974882 7.54376 1.43316C8.47534 1.18515 9.43324 1.06056 10.3948 1.06233C11.3878 1.06233 12.3458 1.1915 13.2438 1.43212C13.9031 0.974285 14.6172 0.608328 15.3678 0.34358C16.0648 0.0967054 17.0578 -0.303295 17.6478 0.376914C18.0478 0.839414 18.1478 1.61441 18.2188 2.20608C18.2988 2.8665 18.3178 3.72691 18.1078 4.58108C18.9108 5.66129 19.3948 6.94983 19.3948 8.354C19.3948 10.4811 18.2888 12.328 16.6518 13.6071C15.8639 14.215 14.9902 14.6915 14.0618 15.0196C14.2758 15.53 14.3948 16.0946 14.3948 16.6873V19.8123C14.3948 20.0886 14.2894 20.3536 14.1019 20.5489C13.9143 20.7443 13.66 20.854 13.3948 20.854H7.39476C7.12954 20.854 6.87519 20.7443 6.68765 20.5489C6.50012 20.3536 6.39476 20.0886 6.39476 19.8123V18.78C5.43976 18.9019 4.63876 18.7936 3.95776 18.4925C3.24576 18.178 2.74976 17.6905 2.37676 17.2238C2.02276 16.7821 1.63676 15.7863 1.07876 15.5925C0.954136 15.5493 0.838906 15.4809 0.739648 15.3913C0.640391 15.3017 0.55905 15.1926 0.500271 15.0702C0.38156 14.8231 0.361952 14.537 0.445761 14.2748C0.529569 14.0127 0.709928 13.7959 0.947162 13.6722C1.1844 13.5486 1.45907 13.5282 1.71076 13.6155C2.37676 13.8467 2.81076 14.3467 3.10776 14.7488C3.58776 15.3946 3.97776 16.2384 4.73776 16.5748C5.05076 16.7134 5.50976 16.804 6.22776 16.7019L6.39476 16.6665C6.39667 16.0996 6.51 15.5391 6.72776 15.0196C5.79935 14.6915 4.92557 14.215 4.13776 13.6071C2.50076 12.328 1.39476 10.4821 1.39476 8.354C1.39476 6.95191 1.87776 5.66441 2.67876 4.58525C2.46876 3.73108 2.48676 2.86858 2.56676 2.20712L2.57176 2.16754C2.64476 1.56129 2.72976 0.847747 3.13776 0.376914C3.72776 -0.303295 4.72176 0.0977473 5.41776 0.344622L5.41876 0.34358Z"
        fill="#EFF4FF"
      />
    </svg>
  );
};

export default Github;
